.single__product-content {
  padding-left: 50px;
}

.product__price {
  /* display: flex; */
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0;
}
.product__price span {
  font-size: 2rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 10px;
}


.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.tabs {
  border-bottom: 1px solid #fde4e4;
}

.tabs h6 {
  cursor: pointer;
}

.tab__content {
  padding: 30px 0px;
}

.tab__content p {
  line-height: 30px;
  color: #212245b6;
}

.tab__active {
  color: #df2020;
}

.contactForm {
  width: 100%;
  height: 100%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
  margin-top: 45px;
}

.form {
  width: 60%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
  margin-top: 40px;
}

.form__group {
  margin-bottom: 30px;
}
.form__group input,
.form__group textarea {
  width: 100%;
  background: transparent;
  padding: 7px 20px;
  border: none;
  border-bottom: 1px solid #2122454c;
}

.form__group input:focus,
.form__group textarea:focus {
  outline: none;
}

.user__name {
  font-weight: 600;
  color: #212245;
}

.user__email {
  font-size: 0.8rem;
}

.feedback__text {
  color: #212245b6;
}

.img__item {
  cursor: pointer;
}
.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 768px) {
  .product__title {
    font-size: 1.2rem;
  }
  .product__price,
  .product__price span {
    font-size: 1rem;
  }
  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .tabs {
    margin-top: 30px;
  }
  .tabs h6 {
    font-size: 0.9rem;
  }

  .tab__content p,
  .user__name,
  .feedback__text {
    font-size: 0.8rem;
  }
  .user__email {
    font-size: 0.7rem;
  }

  .form {
    width: 100% !important;
    margin-top: 50px;
  }

  .form__group input::placeholder,
  .form__group textarea::placeholder {
    font-size: 0.8rem;
  }
  .related__Product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }
}
