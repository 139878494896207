@import url(https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Istok+Web&display=swap);
/* google fonts */

/* reset default style */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* base style */

body {
  background: #fcfcfc;
  font-family: "Istok Web", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "RocknRoll One", sans-serif !important;
}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #212245;
}

section {
  padding: 60px 0px;
}

@media only screen and (max-width: 768px) {
  section {
    padding: 40px 0px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }
}

.brand__title {
  font-size: 2.5rem;
  line-height: 55px;
}

.header {
  width: 100%;
  height: 100px;
  line-height: 100px;
}

.logo {
  text-align: left;
}
.logo img {
  width: calc(100% - 70%);
  object-fit: contain;
  margin-top: -40px;
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-top: -40px;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  transition: 0.3s;
}

.right-menu a {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  transition: 0.3s;
}

.right-menu a:hover {
  color: #df2020;
}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon i,
.user i,
.mobile__menu i {
  font-size: 1.3rem;
  color: #212245;
  cursor: pointer;
}

.cart__icon {
  position: relative;
}

.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

.header__shrink {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 5px 5px 15px -5px #fde4e4;
}

@media only screen and (max-width: 992px) {
  .logo img {
    width: calc(100% - 70%);
  }
  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 0.9rem;
  }

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }

  .nav__right span {
    margin-left: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
  }

  .logo img {
    margin-top: -20px !important;
  }
  .logo h5 {
    margin-top: -55px;
  }
}

.footer {
  padding-top: 50px;
  padding-bottom: 30px;
  background: #fde4e4;
}

.footer__logo img {
  width: calc(100% - 80%);
  margin-bottom: 5px;
}

.footer__logo h5 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer__logo p {
  font-size: 0.9rem;
  color: #333;
  line-height: 28px;
}

.delivery__time-item {
  background: transparent !important;
}

.delivery__time-item span {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
}

.delivery__time-item p {
  color: #212245;
}
.footer__title {
  font-size: 1.1rem;
  color: #212245;
  font-weight: 600;
}

.newsletter {
  padding: 5px;
  border: 1px solid #212245;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.newsletter input {
  background: transparent;
  border: none;
  outline: none;
}

.newsletter input:focus {
  outline: none;
}

.newsletter span {
  background: #df2020;
  padding: 5px 30px;
  border-radius: 5px;
}

.newsletter span i {
  color: #fff;
}

.newsletter input::-webkit-input-placeholder {
  color: #212245;
}

.newsletter input::placeholder {
  color: #212245;
}

.social__links span i {
  padding: 5px;
  background: #df2020;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.copyright__text {
  font-size: 0.8rem;
  color: #df2020;
}

@media only screen and (max-width: 992px) {
  .newsletter input {
    padding: 7px;
    font-size: 0.7rem;
  }
  .newsletter span {
    padding: 4px 20px;
    font-size: 0.7rem;
  }

  .brand__name{
    margin-top: 1px !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer__title {
    font-size: 0.8rem;
  }

  .brand__name{
    margin-top: 1px !important;
  }

  .delivery__time-item span {
    font-size: 0.8rem;
  }

  .delivery__time-item p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .copyright__text {
    text-align: center;
  }

  .social__links p {
    font-size: 0.8rem;
  }

  .social__links span {
    padding: 4px;
  }
  .social__links span a {
    font-size: 0.7rem;
  }

  .brand__name{
    margin-top: 1px !important;
  }
}

.hero__content {
  padding-top: 70px;
}

.hero__title {
  font-size: 2.5rem;
  line-height: 55px;
}

.hero__title span {
  color: #df2020;
}

.hero__content p {
  font-size: 1rem;
  line-height: 30px;
  color: #777;
}

.hero__btns button {
  border: none;
  outline: none;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.order__btn {
  background: #df2020;
  color: #fff;
  transition: 0.3s;
}

.order__btn:hover {
  background: #212245;
}
.all__foods-btn {
  background: transparent;
  border: 1px solid #df2020 !important;
}

.all__foods-btn a {
  color: #df2020;
}

.hero__service p {
  color: #212245;
  font-weight: 600;
  font-size: 0.9rem;
}

.shipping__icon i {
  padding: 5px;
  background: #df2020;
  color: #fff;
  border-radius: 50%;
  font-weight: 500 !important;
}

.category__item {
  background: #fde4e4;
  padding: 30px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.category__item:hover {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

@media only screen and (max-width: 758px){
  .category__item {
    padding: 20px 15px;
  }

  .category__item img{
    width: 40px;
    height: 40px;
  }

  .category__item h6 {
    font-size: 0.8rem;
  }
}
.feature__subtitle {
  color: #df2020;
}

.feature__title span {
  color: #df2020;
}

.feature__text {
  color: #777;
}

.feature__item p {
  color: #777;
}

.food__category {
  background: #df2020;
  text-align: center;
  margin-top: 30px;
  padding: 20px 0px;
  border-radius: 8px;
}

.food__category button {
  border: none;
  outline: none;
  padding: 7px 20px;
  background: transparent;
  color: #fff;
}

.food__category button img {
  width: 20px;
  height: 20px;
}

.foodBtnActive {
  background: #fff !important;
  border-radius: 5px;
  color: #df2020 !important;
}

.tasty__treat-title span {
  color: #df2020;
}

.tasty__treat-desc {
  color: #777;
  line-height: 28px;
}

.choose__us-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.choose__us-title i {
  font-weight: 500 !important;
  color: #df2020;
}

.choose__us-desc {
  color: #777;
}

.testimonial__subtitle {
  color: #df2020;
}

.testimonial__title span {
  color: #df2020;
}

.testimonial__desc {
  color: #777;
  line-height: 30px;
}

@media only screen and (max-width: 768px) {
  .hero__content h5 {
    font-size: 1rem;
  }

  .hero__content p {
    font-size: 0.8rem;
    line-height: 25px;
  }

  .hero__title {
    font-size: 1.5rem;
    line-height: 40px;
  }

  .hero__btns button {
    padding: 5px 15px;
    font-size: 0.7rem;
    margin-left: 10px !important;
  }

  .hero__service p {
    font-size: 0.7rem !important;
    margin-left: 10px !important;
  }

  .feature__item h5 {
    font-size: 0.9rem;
  }

  .feature__item p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .feature__subtitle {
    font-size: 1rem;
  }
  .feature__text {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .food__category {
    padding: 15px;
  }
  .food__category button {
    padding: 5px 15px;
    font-size: 0.7rem;
  }

  .tasty__treat-desc {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  .choose__us-title {
    font-size: 0.8rem;
  }

  .choose__us-desc {
    font-size: 0.7rem;
  }

  .testimonial__subtitle {
    font-size: 1rem;
  }
  .testimonial__desc {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 576px) {
  .hero__content {
    text-align: center;
  }

  .hero__btns {
    justify-content: center;
  }

  .hero__service {
    justify-content: center;
  }
  .why__choose-us {
    padding-top: 0 !important;
  }
}

.product__item {
  /* border: 1px solid #fde4e4; */
  text-align: center;
  padding: 30px;
  cursor: pointer;
}

.product__img {
  margin-bottom: 20px;
  transition: 0.4s;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.product__img:hover {
  -webkit-transform: scale(2);
          transform: scale(2);
}

.product__content h5 a {
  color: #212245;
  font-size: 1rem;
}

.product__content h5 {
  margin-bottom: 30px;
}

.product__price {
  font-weight: 600;
  font-size: 1.2rem;
  color: #df2020;
}

.addTOCart__btn {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

.like_button {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #bbb;
  box-sizing: border-box;
}

@media only screen and (max-width: 992px) {
  .product__content h5 a {
    font-size: 0.8rem;
  }
  .product__item {
    padding: 20px;
  }
  .addTOCart__btn {
    padding: 5px 15px;
    font-size: 0.7rem;
  }
}

.review__text {
  color: #777;
  line-height: 30px;
  margin: 40px 0px;
}

.slider__content img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .review__text {
    font-size: 0.8rem;
    margin: 20px 0px;
  }

  .slider__content img {
    width: 35px;
    height: 35px;
  }

  .slider__content h6 {
    font-size: 0.8rem;
  }
}

.common__section {
  background: linear-gradient(#212245b2, #212245b2),
    
    url(/static/media/contact_page_bg.f6a8e592.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 80px;
}
.search__widget,
.sorting__widget select {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}
.search__widget input {
  border: none;
}

.search__widget input:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .search__widget {
    width: 100%;
    margin-bottom: 20px;
  }
  .search__widget input {
    width: 100%;
    font-size: 0.8rem;
  }
  .sorting__widget select {
    width: 100% !important;
    font-size: 0.8rem;
  }
}

.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  list-style: none;
  margin-top: 30px;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #212245;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.paginationBttns a:hover {
  background: #df2020;
}

.single__product-content {
  padding-left: 50px;
}

.product__price {
  /* display: flex; */
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-bottom: 0;
}
.product__price span {
  font-size: 2rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-top: 10px;
}


.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.tabs {
  border-bottom: 1px solid #fde4e4;
}

.tabs h6 {
  cursor: pointer;
}

.tab__content {
  padding: 30px 0px;
}

.tab__content p {
  line-height: 30px;
  color: #212245b6;
}

.tab__active {
  color: #df2020;
}

.contactForm {
  width: 100%;
  height: 100%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
  margin-top: 45px;
}

.form {
  width: 60%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
  margin-top: 40px;
}

.form__group {
  margin-bottom: 30px;
}
.form__group input,
.form__group textarea {
  width: 100%;
  background: transparent;
  padding: 7px 20px;
  border: none;
  border-bottom: 1px solid #2122454c;
}

.form__group input:focus,
.form__group textarea:focus {
  outline: none;
}

.user__name {
  font-weight: 600;
  color: #212245;
}

.user__email {
  font-size: 0.8rem;
}

.feedback__text {
  color: #212245b6;
}

.img__item {
  cursor: pointer;
}
.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 768px) {
  .product__title {
    font-size: 1.2rem;
  }
  .product__price,
  .product__price span {
    font-size: 1rem;
  }
  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .tabs {
    margin-top: 30px;
  }
  .tabs h6 {
    font-size: 0.9rem;
  }

  .tab__content p,
  .user__name,
  .feedback__text {
    font-size: 0.8rem;
  }
  .user__email {
    font-size: 0.7rem;
  }

  .form {
    width: 100% !important;
    margin-top: 50px;
  }

  .form__group input::-webkit-input-placeholder, .form__group textarea::-webkit-input-placeholder {
    font-size: 0.8rem;
  }

  .form__group input::placeholder,
  .form__group textarea::placeholder {
    font-size: 0.8rem;
  }
  .related__Product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }
}

.cart__item-info img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.cart__product-title {
  font-size: 0.8rem;
  font-weight: 600;
}

.cart__product-price {
  font-size: 0.8rem;
}

.cart__product-price span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #df2020;
}

.increase__decrease-btn {
  background: #fde4e4;
  padding: 2px 5px;
  border-radius: 5px;
}

.increase__btn,
.decrease__btn {
  cursor: pointer;
}

.delete__btn {
  font-size: 1.1rem;
  font-weight: 600;
}

.cart__item {
  margin-bottom: 15px;
  border-bottom: 1px solid #fde4e4;
}

@media only screen and (max-width: 576px) {
  .delete__btn {
    font-size: 1rem;
  }
}

.cart__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.639);
  z-index: 99999;
}

.cart {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background: #fff;
  z-index: 999999;
}

.cart__close {
  width: 100%;
  height: 60px;

  padding: 10px 20px;
}

.cart__close span i {
  font-size: 1.1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}
.cart__item-list {
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.cart__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 20px;
  width: 100%;
  height: 80px;
  background: #df2020;
}

.cart__bottom h6 {
  font-size: 1rem;
  color: #fff;
}

.cart__bottom h6 span {
  font-size: 1.3rem;
  font-weight: 600;
}

.cart__bottom button {
  border: none;
  padding: 4px 20px;
  border-radius: 5px;
  background: #fff;
  color: #212245;
}

.cart__bottom button a {
  color: #212245;
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .cart {
    width: 300px !important;
  }
}

