.product__item {
  /* border: 1px solid #fde4e4; */
  text-align: center;
  padding: 30px;
  cursor: pointer;
}

.product__img {
  margin-bottom: 20px;
  transition: 0.4s;
  transform: scale(1.5);
}

.product__img:hover {
  transform: scale(2);
}

.product__content h5 a {
  color: #212245;
  font-size: 1rem;
}

.product__content h5 {
  margin-bottom: 30px;
}

.product__price {
  font-weight: 600;
  font-size: 1.2rem;
  color: #df2020;
}

.addTOCart__btn {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

.like_button {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #bbb;
  box-sizing: border-box;
}

@media only screen and (max-width: 992px) {
  .product__content h5 a {
    font-size: 0.8rem;
  }
  .product__item {
    padding: 20px;
  }
  .addTOCart__btn {
    padding: 5px 15px;
    font-size: 0.7rem;
  }
}
