.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  list-style: none;
  margin-top: 30px;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #212245;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.paginationBttns a:hover {
  background: #df2020;
}
